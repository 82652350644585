let lazyOffset = "0px 0px 0px 0px";
const watchClasses = ["img.lazy", "source.lazy", ".bg-lazy", ".t-box"]
const deleteClasses = ['t-fade', 't-slide', 't-box', "bg-lazy", "lazy"]
const deleteParentClasses = ["image--lazy"]
const userAgent = document.querySelector('body').dataset.browser;
export function lazyload(target) {
	let lazyElements = [].slice.call(target.querySelectorAll(watchClasses.join(', ')));

	/* Execute after DOM loaded */
	target.addEventListener("DOMContentLoaded", function() {
		lazyLoader(lazyElements)
	});
}

function lazyLoader(array) {
	/* Check if browser is IE*/
	if (userAgent != 'ie') {
		/* Detect if in viewport and load images */
		if ("IntersectionObserver" in window) {
			let observ = new IntersectionObserver(function(entries, observer) {
				entries.forEach(function(entry) {
					if (entry.isIntersecting) {
						let element = entry.target;
						if (element.dataset.src) {
							element.src = element.dataset.src;
						}
						if (element.dataset.srcset) {
							element.srcset = element.dataset.srcset;
						}
						element.classList.remove(...deleteClasses);
						element.parentElement.classList.remove(...deleteParentClasses);
						observ.unobserve(element);
					}
				});
			}, {
				rootMargin: lazyOffset
			});

			array.forEach(function(item) {
				observ.observe(item);
			});
		}
	}
}