import {
	xhrRequest
} from './../functions/request'

async function nav() {
	let navBarToggler = document.querySelector('.nav__toggler'),
		body = document.body;

	let windowWidth = window.innerWidth;


	document.addEventListener('resize', () => {
		windowWidth = window.innerWidth;
	})
	navBarToggler.addEventListener("click", function () {
		navBarToggler.classList.toggle('nav__toggler--active');
		body.classList.toggle('nav-is-open');
	});
	document.querySelectorAll('.menu__item--dropdown__wrapper').forEach((dropdown, i) => {
		let innerHeight = dropdown.querySelector('ul').offsetHeight;
		dropdown.style.maxHeight = innerHeight + 'px';
	});

	fitLanguage();
	fitSearchResult()
	window.addEventListener('resize', function () {
		fitLanguage();
		fitSearchResult()
	})

	document.querySelectorAll('.menu__item--dropdown .menu__item__link').forEach(element => {
		element.addEventListener('click', (e) => {
			e.preventDefault();
			e.target.parentElement.classList.toggle('activee');
		})
	});

	function fitLanguage() {
		let lang = document.querySelector('.nav .language');
		if (lang) {
			let langWidth = lang.offsetWidth + 20;
			let sideWidth = (window.innerWidth - document.querySelector('.plate--container').offsetWidth) / 2;
			if (langWidth < sideWidth) {
				lang.classList.add('language--absolute')
				lang.parentElement.classList.remove('has-lang')
			} else {
				lang.classList.remove('language--absolute')
				lang.parentElement.classList.add('has-lang')
			}
		}
	}

	function fitSearchResult() {
		let sResult = document.querySelector('.menu_item__search__wrapper');
		let navH = document.querySelector('.nav').offsetHeight;
		let searchH = document.querySelector('.plate--container--submenu').offsetHeight;
		sResult.style.maxHeight = (window.innerHeight - navH * 2 - searchH) + 'px'
	}
	/* Submenu */
	let submenuWrapper = document.querySelector('.nav .menu_item__submenu__wrapper');
	let navSearchForm = document.querySelector('.nav .search');
	let navSearch = document.querySelector('.nav .menu_item__search input');
	let headerSearch = document.querySelector('.header .search input');
	let navSearchResult = document.querySelector('.nav .menu_item__search__result');
	let activeSubmenu = false;
	let activeSubmenuItem = false;

	document.querySelectorAll('.menu__item__has-submenu').forEach((submenu, i) => {
		let target = document.querySelector('#' + submenu.dataset.for)

		if (target.querySelector('.menu_item__submenu--row')) {
			target.style.maxHeight = target.querySelector('.menu_item__submenu--row').offsetHeight + 'px';
			console.log(target.querySelector('.menu_item__submenu--row'), target.querySelector('.menu_item__submenu--row').offsetHeight);

		}
		submenu.addEventListener('click', (e) => {
			if (windowWidth > 1200) {
				e.preventDefault();
				if (activeSubmenu) {
					activeSubmenu.classList.remove('active');
					activeSubmenuItem.classList.remove('active');
				}
				if (activeSubmenuItem == submenu) {
					activeSubmenu.classList.remove('active');
					activeSubmenuItem.classList.remove('active');
					submenuWrapper.classList.remove('active');
					activeSubmenu = false;
					activeSubmenuItem = false;
				} else {
					activeSubmenuItem = submenu
					activeSubmenu = target;
					if (activeSubmenu.querySelector('.menu_item__submenu--row')) {
						activeSubmenu.style.maxHeight = activeSubmenu.querySelector('.menu_item__submenu--row').offsetHeight + 'px';
					}
					activeSubmenu.classList.add('active');
					activeSubmenuItem.classList.add('active');
					submenuWrapper.classList.add('active');
				}
				submenuWrapper.classList.remove('active--search')
				navSearch.blur();
			}
		})
		// document.querySelector('.menu_item__submenu__wrapper').addEventListener('mouseleave', () => {
		// 	if (activeSubmenu) {
		// 		activeSubmenu.classList.remove('active');
		// 		activeSubmenuItem.classList.remove('active');
		// 	}
		// 	submenuWrapper.classList.remove('active');
		// 	activeSubmenu = false;
		// 	activeSubmenuItem = false;
		// })
	});

	// Search
	if (headerSearch) {
		headerSearch.addEventListener('focus', () => {
			submenuWrapper.classList.add('active--search')
			navSearch.focus();
			if (activeSubmenu) {
				activeSubmenu.classList.remove('active');
				activeSubmenuItem.classList.remove('active');
			}
			submenuWrapper.classList.remove('active');
			activeSubmenu = false;
			activeSubmenuItem = false;
		})
	}
	navSearch.addEventListener('focus', () => {
		submenuWrapper.classList.add('active--search')
		setTimeout(function () {
			if (activeSubmenu) {
				activeSubmenu.classList.remove('active');
				activeSubmenuItem.classList.remove('active');
			}
			submenuWrapper.classList.remove('active');
			activeSubmenu = false;
			activeSubmenuItem = false;
		}, 100);
	})

	document.addEventListener('click', function (event) {
		if (windowWidth > 1200) {
			var isClickSubmenuWrapper = submenuWrapper.contains(event.target);
			var isClickHeaderSearch = false,
				isClickSubmenuItem = false;
			if (headerSearch) {
				isClickHeaderSearch = headerSearch.contains(event.target);
			}
			document.querySelectorAll('.menu__item__has-submenu').forEach((item, i) => {
				if (item.contains(event.target)) {
					isClickSubmenuItem = item.contains(event.target);
				}
			});

			document.querySelectorAll('.menu__item--dropdown .menu__item__link').forEach(element => {
				if (!element.contains(event.target)) {
					element.parentElement.classList.remove('activee');
				}
			});
			if (!isClickSubmenuWrapper && !isClickHeaderSearch && !isClickSubmenuItem) {
				submenuWrapper.classList.remove('active--search')
				submenuWrapper.classList.remove('active')
				if (activeSubmenu) {
					activeSubmenu.classList.remove('active');
					activeSubmenuItem.classList.remove('active');
				}
			}
		} else {
			if (!document.querySelector('.nav__menu').contains(event.target) && !document.querySelector('.nav__toggler').contains(event.target)) {
				document.querySelector('.nav__toggler').classList.remove('nav__toggler--active')
			}
		}
	});
	// navSearch.addEventListener('focus', () => {
	// 	submenuWrapper.classList.add('active--search')
	// })
	// navSearch.addEventListener('focusout', () => {
	// 	submenuWrapper.classList.remove('active--search')
	// })
	// submenuWrapper.addEventListener('mouseover', () => {
	// 	submenuWrapper.classList.add('active')
	// })
	// submenuWrapper.addEventListener('mouseleave', () => {
	// 	submenuWrapper.classList.remove('active')
	// })

	navSearchForm.addEventListener('submit', (e) => {
		if (window.innerWidth > 991) {
			e.preventDefault();
		}
	})
	navSearchForm.querySelector('svg').addEventListener('click', () => {
		if (window.innerWidth > 991) {
			navSearchForm.submit();
		}
	})
	let timeout;
	let result;
	navSearch.addEventListener('keyup', () => {
		if (window.innerWidth > 991) {
			clearTimeout(timeout);
			let keyupValue = navSearch.value;
			timeout = setTimeout(function () {
				async function search() {
					result = await xhrRequest('search', {
						q: keyupValue
					}, false, true)
				}
				search().then(() => {
					navSearchResult.innerHTML = result;
					document.querySelector('.menu_item__search__wrapper').style.height = navSearchResult.offsetHeight + 'px';
				}).catch(error => console.log(error))
			}, 500);
		}
	})
}

function outsideClick(event, notelem) {
	// check outside click for multiple elements
	var clickedOut = true,
		i, len = notelem.length;
	for (i = 0; i < len; i++) {
		if (event.target == notelem[i] || notelem[i].contains(event.target)) {
			clickedOut = false;
		}
	}
	if (clickedOut) return true;
	else return false;
}

function addEvents(element, listeners, funct) {
	listeners.forEach((listener, i) => {
		if (listener == 'init') {
			funct();
		} else {
			element.addEventListener(listener, funct());
		}
	});
}

export {
	nav
}