const links = document.getElementsByTagName('a'),
	supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
async function scrollTo() {
	window.addEventListener('DOMContentLoaded', () => {

		Array.from(links).forEach((link) => {
			link.addEventListener('click', (e) => {
				let targetID = link.getAttribute('href');

				if (targetID.includes('#') && targetID != '#' && window.location.href.includes(targetID.split('#')[0])) {
					e.preventDefault();
					let targetAnchor = document.querySelector('#' + targetID.split('#')[1]);
					if (targetAnchor) {

						scrollToElem(targetAnchor);
						let submenus = document.querySelector('.menu_item__submenu__wrapper')
						if (submenus) {
							submenus.classList.remove('active');
						}
					}
				};
			})
		});


	});
}
const nativeSmoothScrollTo = positionTop => {
	window.scroll({
		behavior: 'smooth',
		left: 0,
		top: positionTop
	});
};

const smoothScrollTo = (to, duration) => {
	const element = document.scrollingElement || document.documentElement,
		start = element.scrollTop,
		change = to - start,
		startDate = +new Date();

	const easeInOutQuad = (t, b, c, d) => {
		t /= d / 2;
		if (t < 1) return c / 2 * t * t + b;
		t--;
		return -c / 2 * (t * (t - 2) - 1) + b;
	};

	const animateScroll = _ => {
		const currentDate = +new Date();
		const currentTime = currentDate - startDate;
		element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
		if (currentTime < duration) {
			requestAnimationFrame(animateScroll);
		} else {
			element.scrollTop = to;
		}
	};
	animateScroll();
};

const scrollToElem = targetAnchor => {
	console.log('scrollto', targetAnchor);
	let elemSelector = targetAnchor.getBoundingClientRect().top + window.scrollY;
	if (!elemSelector && elemSelector != 0) {
		return;
	}

	let positionTop = elemSelector - 100;

	if (supportsNativeSmoothScroll) {
		nativeSmoothScrollTo(positionTop);
	} else {
		smoothScrollTo(positionTop, 600);
	}
};

export {
	scrollTo,
	scrollToElem
}