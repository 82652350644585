// Import Swiper and modules
import '../../config/node_modules/regenerator-runtime/runtime';

/* Libraries */
// import {
// 	gsap
// } from "../../config/node_modules/gsap";
function updateHeights() {
	document.querySelectorAll('.sidebar__part__subsublist').forEach(list => {
		let height = 0;
		Array.from(list.children).forEach(ch => {
			height += ch.offsetHeight
		})
		list.style.height = height + 'px'
	})
	setTimeout(() => {
		document.querySelectorAll('.sidebar__part__sublist').forEach(list => {
			let height = 0;
			Array.from(list.children).forEach(ch => {
				height += ch.offsetHeight
			})
			list.style.height = height + 'px'
		})
	}, 20);
}
updateHeights()
document.querySelectorAll('.sidebar__button').forEach(btn => {
	btn.addEventListener('click', e => {
		e.preventDefault(); e.stopPropagation();
		e.target.parentElement.classList.toggle('active');
		if (e.target.parentElement.classList.contains('active')) {
			e.target.parentElement.parentElement.classList.add('active');
		} else {
			e.target.parentElement.parentElement.classList.remove('active');
		}
		let int = setInterval(() => {
			updateHeights()
		}, 5);
		setTimeout(() => {
			clearInterval(int)
		}, 200);
	})
})

async function loadHeaderVideo() {
	if (document.querySelector('.header video')) {
		let headerVideo = await import('./scripts/header_video');
		headerVideo.headerVideo();
	}
}
loadHeaderVideo();

async function loadDownloadPopup() {
	if (document.querySelector('#download_popup')) {
		let popup = await import('./scripts/modules/download-popup');
		popup.downloadPopup();
	}
}
loadDownloadPopup();

async function loadSwiper() {
	if (document.querySelector('.swiper-wrapper')) {
		let swiper = await import('./scripts/modules/swiper');
		swiper.swiper();
	}
}
loadSwiper();

/* Modules */
async function loadFilter() {
	if (document.querySelector('.filter__result')) {
		let filter = await import('./scripts/modules/filter/filter');
		filter.filter();
	}
}
loadFilter();


async function loadImgTextDropDown() {
	if (document.querySelector('.img_text_dropdown')) {
		let func = await import('./scripts/img_text_dropdown');
		func.imgTextDropdown();
	}
}
loadImgTextDropDown();

async function loadFormSteps() {
	if (document.querySelector('.form_steps')) {
		let formSteps = await import('./scripts/modules/form/form_steps');
		formSteps.initFormSteps();
	}
}
loadFormSteps();

async function loadForm() {
	if (document.querySelector('.contact_form')) {
		let form = await import('./scripts/modules/form/form');
		form.form();
	}
}
loadForm();

async function loadvimeo() {
	if (document.querySelector('.vimeo')) {
		let vimeo = await import('./scripts/vimeo');
		vimeo.vimeo();
	}
}
loadvimeo();
async function loadAccordion() {
	if (document.querySelector('.accordion')) {
		let accordion = await import('./scripts/modules/accordion');
		accordion.accordion();
	}
}
loadAccordion();

import {
	nav
} from './scripts/modules/nav'
nav();

async function loadVideo() {
	if (document.querySelector('.video')) {
		let form = await import('./scripts/modules/video');
		form.video();
	}
}
loadVideo();

async function loadLightbox() {
	if (document.querySelector('.lightbox, .header__popup_video')) {
		let lightbox = await import('./scripts/modules/lightbox');
		lightbox.lightbox();
	}
}
loadLightbox();
async function loadMap() {
	if (document.querySelector('.map')) {
		let map = await import('./scripts/modules/map');
		map.map(document.querySelector('.news_section__inner'));
	}
}
loadMap()

/* Functions */
import {
	lazyload
} from './scripts/functions/lazyload'
lazyload(document)
import {
	scrollTo
} from './scripts/functions/scrollTo';
scrollTo()
async function loadUpdateCss() {
	function inIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	if (inIframe()) {
		let loadUpdateCsss = await import('./scripts/functions/updatecss');
		loadUpdateCsss.updateCss();
	}
}
loadUpdateCss()

/* Styles */
import './styles/style';