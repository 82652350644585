const axios = require('../../../../config/node_modules/axios').default;
let lang = document.getElementsByTagName('html')[0].getAttribute('lang') + '/';
if (!window.location.href.includes('/' + lang)) {
	lang = '';
}
async function xhrRequest(path, data, state, enableAxios) {
	if (enableAxios) {
		return new Promise(resolve => {
			axios.post('/' + lang + path, data)
				.then(function(response) {
					resolve(response.data);
				})
				.catch(function(error) {
					console.log("Error, er ging iets mis. Neem contact op met de beheerder van deze website", error);
				});
			if (state) {
				let i, text = []
				for (i in data) {
					if (data.hasOwnProperty(i)) {
						if ((data[i].length > 0 || i == 'page') && encodeURIComponent(data[i]) != "") {
							text.push(i + "=" + encodeURIComponent(data[i]));
						}
					}
				}
				text = text.join("&").split(' ').join('_');
				window.history.pushState(null, null, "?" + text);
			}
		});
	} else {
		return new Promise(resolve => {
			var text = [],
				language = document.getElementsByTagName('html')[0].getAttribute('lang'),
				i;
			for (i in data) {
				if (data.hasOwnProperty(i)) {
					text.push(i + "=" + encodeURIComponent(data[i]));
				}
			}
			text = text.join("&").split(' ').join('_');
			var url = '/' + language + '/' + path + "?" + text;
			if (state) {
				window.history.pushState(null, null, "?" + text);
			}
			var xhr = window.XMLHttpRequest ? new XMLHttpRequest : window.ActiveXObject ? new ActiveXObject("Microsoft.XMLHTTP") : (alert("Browser does not support XMLHTTP."), false);
			xhr.onreadystatechange = text;
			xhr.open("GET", url, true);
			xhr.setRequestHeader("Content-Type", "application/json");
			xhr.send(text);
			xhr.onload = function() {
				let result = xhr.response;
				resolve(xhr.response);
			};
		});
	}
}
export {
	xhrRequest
}